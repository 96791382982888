<template>
  <b-row v-if="$route.meta.breadcrumb || $route.meta.pageTitle" class="content-header">
    <!-- Content Left -->
    <b-col class="content-header-left mb-2" cols="12" md="9">
      <b-row class="breadcrumbs-top">
        <b-col cols="12">
          <h3 class="content-header-title float-left pr-1 mb-0">{{ $route.meta.pageTitle }}</h3>
          <div class="breadcrumb-wrapper">
            <b-breadcrumb>
              <b-breadcrumb-item :to="hasRole('Student') ? '/learning' : '/'">
                <feather-icon icon="HomeIcon" size="16" class="align-text-top" />
              </b-breadcrumb-item>
              <b-breadcrumb-item
                v-for="(item, index) in $route.meta.breadcrumb"
                :key="index"
                :active="item.active"
                :to="item.href ? item.href : item.to"
              >
                <!-- {{ item.text != 'edit_bread' ?   item.text : 'Edit ' + bread_val}} -->
                {{
                item.text == 'edit_bread' ? 'edit ' + bread_val :
                (item.text == 'permissions_bread' ? bread_val + ' permissions' :
                (item.text == 'show_bread' ? 'show ' + bread_val :
                (item.text == 'preview_bread' ? 'Preview ' + bread_val :
                (item.text == 'add_bread' ? bread_val : item.text) ) ) )
                }}
              </b-breadcrumb-item>
              <!-- <b-breadcrumb-item v-if="bread_val">
                {{bread_val}}
              </b-breadcrumb-item>-->
            </b-breadcrumb>
          </div>
        </b-col>
      </b-row>
    </b-col>

    <!-- Content Right -->
    <b-col class="content-header-right text-md-right d-md-block d-none mb-1" md="3" cols="12">
      <b-dropdown variant="link" no-caret toggle-class="p-0" right>
        <!-- <template #button-content>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="btn-icon"
          >
            <feather-icon icon="SettingsIcon" />
          </b-button>
        </template>-->

        <b-dropdown-item :to="{ name: 'apps-todo' }">
          <feather-icon icon="CheckSquareIcon" size="16" />
          <span class="align-middle ml-50">Todo</span>
        </b-dropdown-item>

        <b-dropdown-item :to="{ name: 'apps-chat' }">
          <feather-icon icon="MessageSquareIcon" size="16" />
          <span class="align-middle ml-50">Chat</span>
        </b-dropdown-item>

        <b-dropdown-item :to="{ name: 'apps-email' }">
          <feather-icon icon="MailIcon" size="16" />
          <span class="align-middle ml-50">Email</span>
        </b-dropdown-item>

        <b-dropdown-item :to="{ name: 'apps-calendar' }">
          <feather-icon icon="CalendarIcon" size="16" />
          <span class="align-middle ml-50">Calendar</span>
        </b-dropdown-item>
      </b-dropdown>
    </b-col>
  </b-row>
</template>

<script>
import {
  BBreadcrumb,
  BBreadcrumbItem,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BButton
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { mapGetters } from "vuex";

export default {
  directives: {
    Ripple
  },

  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton
  },
  computed: {
    ...mapGetters({
      bread_val: "global/bread_val"
    })
  }
};
</script>
